import './Card.css'
import React from 'react'

function Card(props) {
  const { key, picture, header, footer, onClick, children, className } = props
  return (
    <div key={header} className={'card ' + className} onClick={onClick}>
      <div className="header">{header}</div>
      {children ? (
        children
      ) : (
        <div
          className="picture"
          style={{ backgroundImage: `url(${picture})` }}
        />
      )}
      <div className="footer">{footer}</div>
    </div>
  )
}

export default Card
