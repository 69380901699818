import React, { useEffect, useState } from 'react';
import { ByLang, imageFromClipboard } from "./Util.jsx";
import translate from "translate";
import Card from './Card.jsx'
import { ImageEditor } from './ImageEditor';
import { useSpeech } from './useSpeech';
import { TranslateIcon, ImageIcon, ClipboardIcon } from './Icons.jsx';

function CardEditor(props) {

    const { className, onChange, card } = props;
    const [pixels, setPixels] = useState();
    const [editor, setEditor] = useState("card");
    const [english, setEnglish] = useState("");
    const [russian, setRussian] = useState("");
    const [picture, setPicture] = useState("");
    const [speakEnglish] = useSpeech({ lang: "en-US" });
    const [speakRussia] = useSpeech({ lang: "ru-RU" });
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);

    function buildJson(english, russian, picture) {
        return {
            picture: picture,
            word: [
                {
                    "lang": "english",
                    "text": english,
                },
                {
                    "lang": "русский",
                    "text": russian,
                }
            ]
        };
    }

    useEffect(() => {
        if (card) {
            // Этого не должно быть здесь
            var frontWord = card.word.find(ByLang("русский"));
            var backWord = card.word.find(ByLang("english"));
            //
            setEnglish(backWord.text);
            setRussian(frontWord.text);
            setPicture(card.picture);
        }
    }, [card]);

    useEffect(() => {
        onChange(buildJson(english, russian, picture));
    }, [english, russian, picture]);

    function pastImageUrl(event) {
        navigator.clipboard.readText().then(text=> setPicture(text), console.warn)
    }

    function pastImage(event) {
        var blob = imageFromClipboard();
        blob.then(b => {
            setPicture(URL.createObjectURL(b));
            setEditor("image");
        },
            e => console.log(e));
    }

    function translateEnglish(e) {
        translate(e, { from: "en", to: "ru" }).then(setRussian, console.error);
    }

    function translateRussia(r) {
        console.log("tr", r);
        translate(r, { from: "ru", to: "en" }).then(setEnglish);
    }

    function onCompleteImageEdit(success, imgDataURL) {
        setEditor("card");
        if (success) setPicture(imgDataURL);
    }

    if (editor == "image")
        return ImageEditor({ pixels: pixels, setPixels: setPixels, url: picture, zoom: zoom, setZoom: setZoom, crop: crop, setCrop: setCrop, onComplete: onCompleteImageEdit });
    else
        return <div className={"card-editor flex flex-row bg-gray-100 p-6" + " " + className} >
            <form className="flex flex-col space-y-4 bg-white p-6 shadow-xl">
                <div className="flex flex-col">
                    <label className="">english</label>
                    <div className="input-group">
                        <input name="english" value={english} onChange={e => setEnglish(e.target.value)} placeholder="english" type="text"></input>
                        <button type="button" onClick={_ => translateEnglish(english)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286H4.545zm1.634-.736L5.5 3.956h-.049l-.679 2.022H6.18z" />
                                <path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm7.138 9.995c.193.301.402.583.63.846-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6.066 6.066 0 0 1-.415-.492 1.988 1.988 0 0 1-.94.31z" />
                            </svg>
                        </button>
                        <button type="button" onClick={_ => speakEnglish(english)}>
                            {TranslateIcon()}
                        </button>
                    </div>
                </div>

                <div className="flex flex-col">
                    <label className="">russian</label>
                    <div className="input-group">
                        <input name="russian" value={russian} onChange={e => setRussian(e.target.value)} placeholder="russian" type="text"></input>
                        <button type="button" onClick={_ => translateRussia(russian)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286H4.545zm1.634-.736L5.5 3.956h-.049l-.679 2.022H6.18z" />
                                <path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm7.138 9.995c.193.301.402.583.63.846-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6.066 6.066 0 0 1-.415-.492 1.988 1.988 0 0 1-.94.31z" />
                            </svg>
                        </button>
                        <button type="button" onClick={_ => speakRussia(russian)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z" />
                                <path d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z" />
                                <path d="M10.025 8a4.486 4.486 0 0 1-1.318 3.182L8 10.475A3.489 3.489 0 0 0 9.025 8c0-.966-.392-1.841-1.025-2.475l.707-.707A4.486 4.486 0 0 1 10.025 8zM7 4a.5.5 0 0 0-.812-.39L3.825 5.5H1.5A.5.5 0 0 0 1 6v4a.5.5 0 0 0 .5.5h2.325l2.363 1.89A.5.5 0 0 0 7 12V4zM4.312 6.39 6 5.04v5.92L4.312 9.61A.5.5 0 0 0 4 9.5H2v-3h2a.5.5 0 0 0 .312-.11z" />
                            </svg>
                        </button>
                    </div>
                </div>

                <div className="flex flex-col">
                    <label className="">picture</label>
                    <div className="input-group">
                        <input name="picture" value={picture} readOnly={true} onChange={e => setPicture(e.target.value)} placeholder="image url" type="text"></input>
                        <button type="button" onClick={pastImage}>
                            {ImageIcon()}
                        </button>
                        <button type="button" onClick={pastImageUrl}>
                         {ClipboardIcon()}
                        </button>
                    </div>
                </div>

                {props.children}
            </form>
            <Card header={russian} footer={english} picture={picture}></Card>
        </div>
}

export default CardEditor;
