function shuffle(a) {
  var j, x, i
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1))
    x = a[i]
    a[i] = a[j]
    a[j] = x
  }
  return a
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index
}

function ByLang(lang) {
  return (item, index, array) => item.lang === lang
}

function NativeToISO6391(native) {
  switch (native) {
    case 'русский':
      return 'ru'
    case 'english':
      return 'en'
  }
}

function getBase64FromImageUrl(url) {
  var img = new Image()

  img.setAttribute('crossOrigin', 'anonymous')

  img.onload = function () {
    var canvas = document.createElement('canvas')
    canvas.width = this.width
    canvas.height = this.height

    var ctx = canvas.getContext('2d')
    ctx.drawImage(this, 0, 0)

    var dataURL = canvas.toDataURL('image/png')

    alert(dataURL.replace(/^data:image\/(png|jpg);base64,/, ''))
  }

  img.src = url
}

function blobToDataURL(blob) {
  return new Promise((resolve, reject) => {
    var a = new FileReader()
    a.onload = function (e) {
      resolve(e.target.result)
    }
    a.readAsDataURL(blob)
  })
}

function urlToDataURL(url) {
  return new Promise((resolve, reject) => {
    fetch(url, { mode: 'no-cors' })
      .then((response) => {
        return response.blob()
      })
      .then((blob) => {
        return blobToDataURL(blob)
      })
      .then(resolve)
  })
}

// function googleAudioUrl(text) {
//     return `https://translate.google.com.vn/translate_tts?ie=UTF-8&q=${text.toLowerCase()}&tl=en&client=tw-ob`;
// }

// function wooordhuntAudioUrl(text) {
//     return `https://wooordhunt.ru/data/sound/sow/uk/${text.toLowerCase()}.mp3`;
// }

function imageFromClipboard(event) {
  return new Promise((resolve, reject) => {
    const ej = (x = null) => reject('image not found')
    navigator.clipboard.read().then(
      (clipboardItems) => {
        console.log(clipboardItems)
        var item = clipboardItems.find((i) =>
          i.types.find((t) => t.startsWith('image')),
        )
        if (item) {
          const type = item.types.find((t) => t.startsWith('image'))
          item.getType(type).then((blob) => {
            resolve(blob)
          })
        } else {
          navigator.clipboard.readText().then((text) => {
            if (text.startsWith('http') || text.startsWith('data:image/')) {
              fetch(text).then((r) => {
                if (r.headers.get('Content-Type').startsWith('image')) {
                  r.blob().then(resolve)
                } else ej()
              })
            } else ej()
          }, ej)
        }
      },
      (er) => reject(er),
    )
  })
}

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */

export default async function cropImage(
  imageSrc,
  pixelCrop,
  rotation = 0,
  mimeType = 'image/jpeg',
) {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  const maxSize = Math.max(image.width, image.height)
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea
  canvas.height = safeArea

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2)
  ctx.rotate(getRadianAngle(rotation))
  ctx.translate(-safeArea / 2, -safeArea / 2)

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5,
  )
  const data = ctx.getImageData(0, 0, safeArea, safeArea)

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
  )

  // As Base64 string
  return canvas.toDataURL(mimeType)

  // As a blob
  // return new Promise(resolve => {
  //     canvas.toBlob(file => {
  //         resolve(URL.createObjectURL(file))
  //     }, mimeType)
  // });
}

function download(content, fileName, contentType) {
  var a = document.createElement('a')
  var file = new Blob([content], { type: contentType })
  a.href = URL.createObjectURL(file)
  a.download = fileName
  a.click()
}

export function hashCode(s) {
  return s.split('').reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0)
}

export function IndexKey(c, ci) {
  return { ...c, key: ci }
}

export function LogItem(c, ci) {
  console.log(c)
  return c
}

export function selectFile(contentType, multiple){
  return new Promise(resolve => {
      let input = document.createElement('input');
      input.type = 'file';
      input.multiple = multiple;
      input.accept = contentType;

      input.onchange = () => {
          let files = Array.from(input.files);
          if (multiple)
              resolve(files);
          else
              resolve(files[0]);
      };

      input.click();
  });
}

export {
  shuffle,
  ByLang,
  NativeToISO6391,
  onlyUnique,
  urlToDataURL,
  blobToDataURL,
  imageFromClipboard,
  cropImage,
  download,
}
