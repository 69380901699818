import { TopicCardMapper, ExtractTopicCards } from './cards'
import Card from './Card.jsx'
import { BuildTopicPhrases } from './Phrase'

export default function Topic(props) {
  const { learnLang, nativeLang, topic, children } = props
  const phrases = topic?.phrases;
  const cardMapper = TopicCardMapper({ nativeLang, learnLang })
  return (
    <div className="flex flex-row flex-wrap bg-slate-100 flex-gap-4">

      {ExtractTopicCards(topic).map(cardMapper).map(Card)}

      {phrases &&
        <div className="w-full flex flex-row flex-wrap flex-gap-4">
          <BuildTopicPhrases {...{ nativeLang, learnLang, phrases }} />
        </div>
      }

      {children}

    </div>
  )
}
