import React, { useEffect, useState } from 'react';
import CardEditor from "./CardEditor.jsx"
import { ByLang, download, selectFile } from "./Util.jsx";
import Card from "./Card.jsx";
import { CleanIcon, ExportIcon, DeleteIcon, GobackIcon, OpenIcon, PlusIcon} from './Icons.jsx'

function Topic(topic, cards) {
    return {
        "topic": topic,
        "cards": cards
    };
}

const emptyTopic = {
    "picture": "",
    "word": [{
        "lang": "english",
        "text": "topic",
    }, {
        "lang": "русский",
        "text": "Тема",
    }]
};

function TopicEditor(params) {
    const { onExit } = params;
    const [cards, setCards] = useState([]);
    const [topic, setTopic] = useState(emptyTopic);
    const [mode, setMode] = useState('list');
    const [card, setCard] = useState(null);
    const [changedCard, setChangedCard] = useState(null);
    const [cardIndex, setCardIndex] = useState(null);

    useEffect(() => {
        const storeTopic = JSON.parse(localStorage.getItem('topic'));
        if (storeTopic) {
            setTopic(storeTopic.topic);
            setCards(storeTopic.cards);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('topic', JSON.stringify(Topic(topic, cards)));
    }, [cards, topic]);

    function SaveJSON() {
        const topicName = topic.word.find(ByLang("english")).text;
        download(JSON.stringify(Topic(topic, cards)), topicName + ".json", "text/json");
        console.log(JSON.stringify(Topic(topic, cards)));
    }

    async function OpenFile() {
        let file = await selectFile('text/json', false);
        var reader = new FileReader();
        reader.readAsText(file);
        reader.onload = e => { 
            var topic = JSON.parse(e.target.result); 
            setCards(topic.cards);
            setTopic(topic.topic);
        };
    }

    function BeginEdit(index) {
        if (index != null) {
            setCardIndex(index);
            setCard(index != -1 ? cards[index] : topic);
        }

        setMode('card');
    }

    function Delete(index) {
        cards.splice(index, 1);
        setCards([...cards]);
    }


    function CancelEdit() {
        setMode('list');
        setCard(null);
        setCardIndex(null);
    }

    function ConfirmEdit() {
        if (cardIndex != null) {
            if (cardIndex != -1) {
                cards[cardIndex] = changedCard;
                setCards([...cards]);
            } else {
                setTopic(changedCard);
            }
        } else {
            setCards([...cards, changedCard]);
        }

        setCard(null);
        setChangedCard(null);
        setCardIndex(null);
        setMode('list');
    }

    function Clear() {
        setCards([]);
        setTopic(emptyTopic);
    }

    var createCard = (c, i) => {
        var frontWord = c.word.find(ByLang("русский"));
        var backWord = c.word.find(ByLang("english"));
        var footer = <div>{backWord.text}
            {i != -1 &&
                <button onClick={(e) => { e.stopPropagation(); Delete(i); }} type="button">
                    {DeleteIcon()}
                </button>}
        </div>
        return <Card key={frontWord.text} header={frontWord.text} footer={footer}
            picture={c.picture} onClick={() => BeginEdit(i)}></Card>;
    }

    if (mode === 'card')
        return <div className="flex flex-col h-full">
            <CardEditor className="h-full" onChange={setChangedCard} card={card}>
                <div className="text-xl text-pink-600 flex justify-between">
                    <button onClick={ConfirmEdit} type="button" className="text-button">ok</button>
                    <button onClick={CancelEdit} type="button" className="text-button">cancel</button>
                </div>
            </CardEditor>
        </div>

    var cds = cards.map(createCard);
    return <div className="topic-editor">
        <div className="topbar">
            <button onClick={onExit} type="button">
                {GobackIcon()}
            </button>
            <button onClick={() => OpenFile()}>
                {OpenIcon()}
                open
            </button>
            <button onClick={() => SaveJSON()}>
                {ExportIcon()}
                export
            </button>
            <button onClick={Clear}>
                {CleanIcon()}
                clean
            </button>
        </div>
        <div className="cards">
            {createCard(topic, -1)}
            {cds}
        </div>

        <button onClick={() => BeginEdit(null)} type="button" className="fab">
            <PlusIcon/>
        </button>
    </div>
}

export default TopicEditor;
