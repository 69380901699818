import React from 'react';
import { LinkCard } from "./LinkCard";
import learn from 'data-url:./image/learn.webp?as=jpeg'
import blocksImage from 'data-url:./image/blocks.jpg'
import editImage from 'data-url:./image/edit.webp?as=jpeg'

export default function MainMenu() {
  return <div className="flex flex-row flex-wrap bg-slate-100 min-h-full min-w-full flex-gap-4">
    <LinkCard header="Учить слова" url="/cards" picture={learn}/>
    <LinkCard header="Составить слово" url="/buildword" picture={blocksImage} />
    <LinkCard header="Редактировать" url="/TopicEditor" picture={editImage} ></LinkCard>
  </div>;
}