import { hashCode } from './Util'

export function useAudio(props) {
  const audio = new Audio(props.source);
  var play = () => {
    if (audio) {
      audio.play();
    }
  };
  return play;
}

export function usePlay() {
  const cache = {};

  return (source) => {
    const id = hashCode(source);
    // console.log("play", id, source, cache);
    if (!(id in cache))
      cache[id] = new Audio(source);

    cache[id].play();
  };
}
