import React from 'react';
import Card from "./Card.jsx";
import { useHistory } from "react-router-dom";


export function LinkCard(params) {
  let history = useHistory();
  const { url } = params;
  return <Card onClick={() => history.push(url)} {...params}></Card>;
}
