import React from 'react';
import Fetch from "./Fetch";
import Topic from "./Topic";
import { BuildWordGame, UseTopicBuildWordGame } from './BuildWord.jsx'
import CardEditor from "./CardEditor.jsx"
import TopicEditor from './TopicEditor.jsx'
import MainMenu from "./MainMenu.jsx"
import Topics from "./topics.jsx";
import {
  BrowserRouter as Router,
  Switch,
  useParams,
  useHistory,
  Route,
} from "react-router-dom";
import { BackCard } from './BackCard';

function SelectTopic(params) {
  const { url } = params;
  let history = useHistory();

  function onTopicSelect(t) {
    history.push(url.replace(":topic", encodeURIComponent(t.url)));
  }

  return <Topics url="/topics.json" onSelect={onTopicSelect} frontLang="русский" backLang="english">
    <BackCard></BackCard>
  </Topics>
}

function WithTopic(params) {
  const { fn } = params;
  let { topic } = useParams();
  let topicd = decodeURIComponent(topic);
  return fn(topicd);
}

const langSettings = { nativeLang: "русский", learnLang: "english" };

function App() {
  return <div className="app h-screen">
    <Router>
      <Switch>
        <Route path="/TopicEditor">
          <TopicEditor onExit={() => window.history.back()}/>
        </Route>
        <Route path="/CardEditor">
          <CardEditor/>
        </Route>
        <Route path="/cards/:topic">
          <WithTopic fn={(topic) => {
            return <Fetch url={topic} onResponse={(r) => <Topic {...langSettings} topic={r} >
              <BackCard className="m-2"></BackCard>
            </Topic>} />
          }}></WithTopic>
        </Route>
        <Route path="/buildword/:topic">
          <WithTopic fn={(topic) =>
            <BuildWordGame onOk={() => window.history.back()}
              {...UseTopicBuildWordGame({ url: topic, frontLang: "русский", backLang: "english" })} />}></WithTopic>
        </Route>
        <Route path="/buildword">
          <SelectTopic url="/buildword/:topic"></SelectTopic>
        </Route>
        <Route path="/cards">
          <SelectTopic url="/cards/:topic">

          </SelectTopic>
        </Route>
        <Route path="/">
          <MainMenu/>
        </Route>
      </Switch>
    </Router>
  </div >
}

export default App;