import './Letters.css';
import { onlyUnique } from "./Util.jsx";
import React, { useEffect, useState } from 'react';


function Letters(props) {
    var { word, onFinish, onError } = props;
    word = word.split('');
    const [srcLetters, setSrcLetters] = useState(word.map((l, i) => i));
    const [current, setCurrent] = useState(0);
    const [error, setError] = useState();
    console.log('letters', props);

    useEffect(() => {
        if (current === word.length && onFinish) onFinish();
        setSrcLetters(word.slice(current).filter(onlyUnique).sort());
    }, [current]);

    function handleClick(i) {
        if (word[current] === i) {
            setCurrent(current + 1);
        } else {
            setError(i);
            onError();
        }
    }

    function fogy(current) {
        return (l, i) => {
            if (i < current) return l
            else if (i === current) return '?'
            else return '';
        }
    }

    return (
        <div className="letters-container">
            <div className="dst">
                {word.map(fogy(current)).map((l, i) => {
                    const currentClass = "select-none letter " + (i === current ? 'current' : '');
                    return <div key={i} className={currentClass}>
                        {l}
                    </div>
                })}
            </div>
            <div className="src">
                {srcLetters.map((l) => {
                    const className = "select-none letter " + (l === error ? 'error' : '')
                    return <div key={l} className={className} onAnimationEnd={() => setError(-1)} onClick={() => handleClick(l)}>
                        {l}
                    </div>
                })}
            </div>
        </div>
    );
};

export default Letters;
