import { useEffect } from 'react';

export function useSpeech(params) {
    const { lang } = params;
    var enSpeech = new SpeechSynthesisUtterance();
    enSpeech.lang = lang;

    function Speak(text) {
        enSpeech.text = text;
        window.speechSynthesis.speak(enSpeech);
    }

    return [Speak];
}
