import Card from './Card.jsx'
import React from 'react'
import { usePlay } from './useAudio.jsx'
import { useSpeech } from './useSpeech.jsx'
import { ByLang, NativeToISO6391 } from './Util.jsx'

export function ExtractTopicCards(topic) {
  return [topic.topic, ...topic.cards]
}

export function TopicCardMapper(props) {
  var { nativeLang, learnLang } = props
  const [speech] = useSpeech({ lang: NativeToISO6391(learnLang) })
  const play = usePlay()

  return (card) => {
    const nativeWord = card.word.find(ByLang(nativeLang))
    const learnWord = card.word.find(ByLang(learnLang))
    const onClick = learnWord.audio
      ? () => play(learnWord.audio)
      : () => speech(learnWord.text)

    return {
      ...card,
      onClick,
      header: nativeWord.text,
      footer: learnWord.text,
    }
  }
}
