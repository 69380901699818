import React from 'react';
import Cropper from 'react-easy-crop';
import { cropImage } from "./Util.jsx";

export function ImageEditor(props) {
    const { pixels, setPixels, onComplete, url, setCrop, crop, zoom, setZoom } = props;

    function onOk() {
        cropImage(url, pixels).then((r) => onComplete(true, r));
    }

    function onCancel() {
        onComplete(false, url);
    }

    function onCropComplete(area, pixels) {
        setPixels(pixels);
    }

    return (
        <div className="bg-pink-800 absolute top-0 bottom-0 left-0 right-0 flex-grow flex flex-col">
            <div className="relative flex-grow">
                <Cropper
                    image={url}
                    crop={crop}
                    zoom={zoom}
                    aspect={1 / 1}
                    minZoom={0.1}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom} />
                <div className="text-xl bottom-0 left-0 right-0 absolute text-pink-800 p-3 flex justify-center">
                    <button onClick={onOk} type="button" className="text-button">ok</button>
                    <button onClick={onCancel} type="button" className="text-button">cancel</button>
                </div>
            </div>

        </div>
    );
}
