import React, { useEffect, useState } from 'react';
import axios from 'axios'

export default function Fetch(props) {
    var { url, onResponse, onLoad, onError } = props;
    const [loading, setLoading] = useState(true);
    const [response, setResponse] = useState(null);
    const [error, setError] = useState();

    onResponse ??= (r) => <div>Результат {JSON.stringify(r)}</div>;
    onError ??= Error;
    onLoad ??= Loading;

    useEffect(() => {
        axios.get(url)
            .then(
                (response) => {
                    setResponse(response.data);
                    setLoading(false);
                }
            ).catch((e) => {
                console.error('error', e);
                setError(e);
                setLoading(false);
            });
    }, []);

    if (loading)
        return Loading();

    if (error)
        return <Error error={error} />;

    return onResponse(response);
}

function Error(params) {
    const { error, retry } = params;
    console.log('error rnd', params);
    return <div className='h-full w-full text-xl flex flex-col items-center justify-center text-white bg-red-500'>
        <svg class="w-12 h-12" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
        <div className='mt-2'>{error.message ? error.message : JSON.stringify(error)}</div>
    </div>
}

function Loading() {
    return <div className="h-full w-full flex justify-center items-center">
        <svg xmlns="http://www.w3.org/2000/svg" className='w-10 h-10 text-slate-600 animate-spin' fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
            </path>
        </svg>
    </div>;
}

