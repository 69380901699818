import { Children } from 'react'
import { useSpeech } from './useSpeech.jsx'
import { NativeToISO6391 } from './Util.jsx'

export function PhraseGroup(props) {
  const { children, className } = props
  const first = children[0]
  const rest = Children.toArray(children).slice(1)
  return (
    <div
      className={
        'phrase-group w-full md:max-w-fit rounded-lg bg-slate-300 p-4 shadow-xl ' +
        className
      }
    >
      {first}
      <div className="grid grid-flow-col gap-2 mt-2">{rest}</div>
    </div>
  )
}

export function Phrase(props) {
  const { text, description, onClick, key } = props
  return (
    <div
      key={key ? key : text}
      onClick={onClick}
      className="md:max-w-fit rounded-lg hover:bg-green-400 active:scale-95 bg-slate-200 p-3 shadow-xl cursor-pointer"
    >
      <div className="text-lg font-bold text-gray-800 leading-none md:whitespace-nowrap">
        {text}
      </div>
      <div className="font-light leading-none  md:whitespace-nowrap">
        {description}
      </div>
    </div>
  )
}

export function TopicPhraseMapper(props) {
  const { learnLang, nativeLang } = props
  const [speech] = useSpeech({ lang: NativeToISO6391(learnLang) })

  return (p) => {
    return {
      ...p,
      text: p[learnLang],
      description: p[nativeLang],
      onClick: () => speech(p[learnLang]),
    }
  }
}

export function BuildTopicPhrases(props) {
  const { learnLang, nativeLang, phrases } = props
  const phraseMapper = TopicPhraseMapper({ learnLang, nativeLang })
  const GroupRender = props?.groupRender ? props?.groupRender : PhraseGroup
  const ItemRender = props?.itemRender ? props.itemRender : Phrase
  return phrases.map((ps, gi) => (
    <GroupRender {...{ key: 'pg' + gi }}>
      {ps.map(phraseMapper).map(ItemRender)}
    </GroupRender>
  ))
}
