import Card from './Card.jsx'
import React from 'react';
import { ByLang } from "./Util";

class Topics extends React.Component {
    constructor(props) {
        super(props);
        this.fetchTopic = this.fetchTopic.bind(this);
        this.state = {
            error: null,
            isLoaded: false,
            source: [],
            topics: []
        };
    }

    fetchTopic(url) {
        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    result.url = url;
                    this.setState({
                        topics: [...this.state.topics, result]
                    });
                    console.log(result);
                },
                (error) => {
                    console.log(url, "error");
                }
            )
    }

    componentDidMount() {
        fetch(this.props.url)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        source: result
                    });
                    result.map(this.fetchTopic);
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render() {
        const { topics } = this.state;
        const { frontLang, backLang, onSelect, children } = this.props;
        
        var cards = topics.map((topic) => {
            const t = topic.topic;
            const frontWord = t.word.find(ByLang(frontLang));
            const backWord = t.word.find(ByLang(backLang));
            const onClick = () => { onSelect(topic); };

            return <Card onClick={onClick} picture={t.picture} header={frontWord.text} footer={backWord.text}></Card>
        });
        return (
            <div className="flex flex-row flex-wrap bg-slate-100 flex-gap-4">
                {cards}
                {children}
            </div>
        );
    }
}

export default Topics;
